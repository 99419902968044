import { useCallback, useState } from "react";
import { useAccount, useWriteContract } from "wagmi";
import { abi as fckNftAbi, address as fckNftAddress } from "../contract/FckNft";

type AllowlistProof = {
  proof: `0x${string}`[];
  quantityLimitPerWallet: bigint;
  pricePerToken: bigint;
  currency: `0x${string}`;
};

type ClaimParams = [
  receiver: `0x${string}`,
  quantity: bigint,
  currency: `0x${string}`,
  pricePerToken: bigint,
  allowlistProof: AllowlistProof,
  data: `0x${string}`
];

export const useMint = () => {
  const [transactionReceiptUrl, setTransactionReceiptUrl] = useState("");
  const [mintStatus, setMintStatus] = useState("");
  const [mintSuccess, setMintSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const { writeContractAsync } = useWriteContract();
  const { address: connectedAccountAddress } = useAccount();

  const mint = useCallback(async () => {
    const write = async () => {
      setLoading(true);
      setMintStatus("WRITING CONTRACT");

      const claimParams: ClaimParams = [
        connectedAccountAddress as `0x${string}`, // _receiver
        BigInt(1), // _quantity
        "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE", // _currency
        BigInt(0), // _pricePerToken (1 ETH in wei)
        {
          proof: [
            "0x0000000000000000000000000000000000000000000000000000000000000000",
          ], // Your merkle proof
          quantityLimitPerWallet: BigInt(1),
          pricePerToken: BigInt(0),
          currency: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
        },
        "0x", // _data (empty bytes)
      ];

      try {
        const result = await writeContractAsync({
          address: fckNftAddress,
          abi: fckNftAbi,
          functionName: "claim",
          args: claimParams,
        });

        setMintStatus("WRITING CONTRACT COMPLETE");
        setMintSuccess(true);
        return result;
      } catch (err) {
        setLoading(false);
        setMintStatus("SOMETHING WENT WRONG");
        console.error("Error writing contract: ", typeof err, err);
        throw err;
      }
    };

    const writeHash = await write();

    setTransactionReceiptUrl(`https://basescan.org/tx/${writeHash}`);
    setMintStatus("MINT COMPLETE");
    setLoading(false);
  }, [connectedAccountAddress, writeContractAsync]);

  const resetMint = () => {
    setTransactionReceiptUrl("");
    setMintStatus("");
    setMintSuccess(false);
    setLoading(false);
  };
  return {
    mint,
    transactionReceiptUrl,
    mintStatus,
    mintSuccess,
    loading,
    resetMint,
  };
};
