import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

import "./App.css";
import { ApplicationProviders } from "./providers/ApplicationProviders";
import { Mint } from "./pages/Mint/Mint";
import { NotFound } from "./pages/NotFound/NotFound";


function App() {
  return (
    <ApplicationProviders>
      <Router>
        <Routes>
          <Route path="/" element={<Mint />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Router>
    </ApplicationProviders>
  );
}

export default App;
