import { Box } from "@mui/material";
import { ConnectKitButton } from "connectkit";
import { ReactNode, memo } from "react";
import logo from "../../assets/fck3.png";

export const PageLayout = memo(function PageLayout({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <Box width="100%" height="100vh" bgcolor="black">
      <Box display="flex" justifyContent="space-between" padding={2}>
        <img src={logo} alt="logo" height={50} />
        <ConnectKitButton />
      </Box>
      <Box>{children}</Box>
    </Box>
  );
});
