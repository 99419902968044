import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

// TODO
export function NotFound() {
    const navigate = useNavigate()

    useEffect(() => {
        navigate('/')
    }, [navigate])

    return (
        <div>
            Page not found
        </div>
    )
}