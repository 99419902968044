import { getDefaultConfig } from "connectkit";
import { base } from "viem/chains";
import { createConfig, http } from "wagmi";

const WALLET_CONNECT_PROJECT_ID = "2502a9ca4b9072ed65e3034e1e8a9499";

export const config = createConfig(
  getDefaultConfig({
    chains: [base],
    transports: {
      [base.id]: http(
        `https://api.developer.coinbase.com/rpc/v1/base/69IcHAn57OgcHnv4sFZ-O23qD2jGeZoo`
      ),
    },
    walletConnectProjectId: WALLET_CONNECT_PROJECT_ID,
    appName: "fuck-meme-coins",
  })
);
