import { memo, useCallback, useEffect, useState } from "react";

import {
  Box,
  IconButton,
  Button as MuiButton,
  Snackbar,
  Stack,
  Typography,
  keyframes,
} from "@mui/material";
import styled from "@emotion/styled";

import { PageLayout } from "../../components/PageLayout/PageLayout";
import { useMint } from "../../hooks/useMint";
import { useAccount } from "wagmi";
import fck1 from "../../assets/fck1.png";
import fck2 from "../../assets/fck2.png";
import fck3 from "../../assets/fck3.png";
import rg from "../../assets/rg.png";
import ReactConfetti from "react-confetti";
import { useWindowSize } from "react-use";

const Button = styled(MuiButton)`
  width: 60px;
  height: 45px;
  border-radius: 12px;
`;

const rotate = keyframes`
0 {
    transform: rotateY(0);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0deg);
  }
`;

const Coin = styled(Box)`
  animation: ${rotate} 4s ease-in-out infinite;
  transition: transform 0.2s;
  img:hover {
    transform: scale(1.1);
  }
`;

export const Mint = memo(function Mint() {
  const [error, setError] = useState("");
  const { width, height } = useWindowSize();

  const { address } = useAccount();
  const { mint, loading, mintStatus, mintSuccess, resetMint } = useMint();

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (mintSuccess) {
      timeoutId = setTimeout(() => {
        resetMint();
      }, 10000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [mintSuccess, resetMint]);

  const handleMintClick = useCallback(async () => {
    try {
      await mint();
    } catch (err) {
      const { message } = err as Error;
      setError(message);
      console.error("Error when minting: ", typeof err, err);
    }
  }, [mint]);

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setError("");
  };

  return (
    <PageLayout>
      {mintSuccess && (
        <>
          <Box position="fixed" bottom={0} right={0} zIndex={999}>
            <img src={rg} alt="rg" />
          </Box>

          <ReactConfetti
            numberOfPieces={100}
            width={width}
            height={height}
            drawShape={(ctx) => {
              ctx.beginPath();
              for (let i = 0; i < 20; i++) {
                const angle = 0.7 * i;
                const x = (0.2 + 1.5 * angle) * Math.cos(angle);
                const y = (0.2 + 1.5 * angle) * Math.sin(angle);
                ctx.lineTo(x, y);
              }
              ctx.stroke();
              ctx.closePath();
            }}
          />
        </>
      )}

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight={250}
        marginTop="10%"
      >
        <Stack
          direction="row"
          spacing={2}
          flexWrap="wrap"
          justifyContent="center"
        >
          {[fck1, fck2, fck3].map((icon, idx) => (
            <Coin key={idx} minWidth={200}>
              <img src={icon} alt={`fck${idx}`} height="auto" width={200} />
            </Coin>
          ))}
        </Stack>

        {address && (
          <Box paddingY={4}>
            <Button
              variant="contained"
              onClick={handleMintClick}
              disabled={!address || loading}
            >
              <Typography variant="button" color="white">
                Mint
              </Typography>
            </Button>
          </Box>
        )}

        {loading && (
          <Typography variant="caption" color="#808080">
            {mintStatus}
          </Typography>
        )}
      </Box>
      <Snackbar
        open={!!error}
        onClose={handleSnackbarClose}
        message={error}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            x
          </IconButton>
        }
      />
    </PageLayout>
  );
});
